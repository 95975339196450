
import "preline/preline";
import { IStaticMethods } from "preline/preline";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import AppRoutes from "./constants/AppRoutes";
import { Home } from "./pages/Home";
import { Imprint } from "./pages/Imprint";
import { Layout } from "./pages/Layout";
import { Privacy } from "./pages/Privacy";
declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

const router = createBrowserRouter(createRoutesFromElements(
  <Route path={AppRoutes.ROOT} element={<Layout />} >
    <Route index element={<Home />} />
    <Route path={AppRoutes.IMPRINT} element={<Imprint />} />
    <Route path={AppRoutes.PRIVACY} element={<Privacy />} />
  </Route>
))

function App() {
  return <>
    <RouterProvider router={router} />
  </>
}

export default App;
