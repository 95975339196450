import { Link, Outlet } from "react-router-dom";
import { Footer } from "../components/Footer";
import HealthiiLogo from "../components/HealthiiLogo";
import AppRoutes from "../constants/AppRoutes";
import ScrollToTop from "../util/ScrollToTop";

const backgroundCss = `
@keyframes move {
  100% {
      transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #141414;
  overflow: hidden;
}

.background span {
  width: 43vmin;
  height: 43vmin;
  border-radius: 43vmin;
  backface-visibility: hidden;
  position: absolute;
  animation: move;
  animation-duration: 15;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.background span:nth-child(0) {
  color: #5f3bf0;
  top: 21%;
  left: 40%;
  animation-duration: 341s;
  animation-delay: -183s;
  transform-origin: 18vw -7vh;
  box-shadow: 86vmin 0 11.682617954026696vmin currentColor;
}
.background span:nth-child(1) {
  color: #a262ff;
  top: 1%;
  left: 88%;
  animation-duration: 70s;
  animation-delay: -164s;
  transform-origin: 18vw 3vh;
  box-shadow: -86vmin 0 11.382297276349112vmin currentColor;
}
.background span:nth-child(2) {
  color: #a262ff;
  top: 76%;
  left: 40%;
  animation-duration: 157s;
  animation-delay: -301s;
  transform-origin: 1vw -11vh;
  box-shadow: 86vmin 0 10.99023771048347vmin currentColor;
}
.background span:nth-child(3) {
  color: #10c49c;
  top: 3%;
  left: 67%;
  animation-duration: 216s;
  animation-delay: -142s;
  transform-origin: 13vw -8vh;
  box-shadow: 86vmin 0 10.991170129335774vmin currentColor;
}
.background span:nth-child(4) {
  color: #a262ff;
  top: 87%;
  left: 52%;
  animation-duration: 228s;
  animation-delay: -56s;
  transform-origin: -13vw 4vh;
  box-shadow: 86vmin 0 11.19599662481546vmin currentColor;
}
.background span:nth-child(5) {
  color: #10c49c;
  top: 77%;
  left: 42%;
  animation-duration: 314s;
  animation-delay: -332s;
  transform-origin: 25vw -18vh;
  box-shadow: 86vmin 0 11.245237425400445vmin currentColor;
}
.background span:nth-child(6) {
  color: #10c49c;
  top: 62%;
  left: 67%;
  animation-duration: 57s;
  animation-delay: -63s;
  transform-origin: 5vw 3vh;
  box-shadow: -86vmin 0 10.805079471777264vmin currentColor;
}
.background span:nth-child(7) {
  color: #a262ff;
  top: 3%;
  left: 45%;
  animation-duration: 156s;
  animation-delay: -270s;
  transform-origin: 13vw -20vh;
  box-shadow: -86vmin 0 11.127693030107979vmin currentColor;
}
.background span:nth-child(8) {
  color: #5f3bf0;
  top: 18%;
  left: 23%;
  animation-duration: 211s;
  animation-delay: -66s;
  transform-origin: 14vw 1vh;
  box-shadow: 86vmin 0 10.929394773043136vmin currentColor;
}
.background span:nth-child(9) {
  color: #a262ff;
  top: 26%;
  left: 7%;
  animation-duration: 265s;
  animation-delay: -154s;
  transform-origin: 23vw -14vh;
  box-shadow: -86vmin 0 11.587500105695383vmin currentColor;
}
.background span:nth-child(10) {
  color: #a262ff;
  top: 49%;
  left: 80%;
  animation-duration: 124s;
  animation-delay: -134s;
  transform-origin: -1vw -1vh;
  box-shadow: -86vmin 0 11.580041507578908vmin currentColor;
}
.background span:nth-child(11) {
  color: #5f3bf0;
  top: 72%;
  left: 35%;
  animation-duration: 329s;
  animation-delay: -258s;
  transform-origin: 7vw 25vh;
  box-shadow: -86vmin 0 11.355039807251819vmin currentColor;
}
.background span:nth-child(12) {
  color: #5f3bf0;
  top: 4%;
  left: 62%;
  animation-duration: 78s;
  animation-delay: -85s;
  transform-origin: 22vw -13vh;
  box-shadow: 86vmin 0 11.454948948613996vmin currentColor;
}
`;

const Background = () => {
  return <>
    <div className="background">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <style>{backgroundCss}</style>
  </>
}

export const Layout = () => {
  return (
    <div className="flex flex-col min-h-screen bg-black text-white relative">
      <Background />

      {/* Scroll to top after navigate to the current page. */}
      <ScrollToTop />

      <main className="flex-grow flex flex-col justify-center items-center px-4 sm:px-6 lg:px-8 z-10 py-12">
        <div className="bg-black/60 rounded-2xl py-8 px-4 sm:px-6 lg:px-8 w-full max-w-4xl">
          <Link to={AppRoutes.ROOT}>
            <HealthiiLogo />
          </Link>
          <Outlet />
        </div>
      </main>

      <div className="w-full flex justify-center z-10">
        <div className="pb-6 w-full max-w-4xl">
          <Footer />
        </div>
      </div>
    </div>
  );
};
