export const Imprint = () => {

    const Spacer = () => {
        return (
            <div style={{
                marginBottom: "0.25rem"
            }} />
        )
    }

    const DoubleSpacer = () => {
        return (
            <div style={{
                marginBottom: "1rem"
            }} />
        )
    }

    return <>
        <div className="text-sm text-gray-200">
            <h1>Impressum</h1>

            <h2>Anbieter</h2>

            Healthii Pharmacy B.V.<br />
            Pannenberg 16a<br />
            5951 DM Belfeld<br />
            Niederlande

            <h2>Kontakt</h2>

            Tel: +49 621 37031972<br />
            E-Mail: pharmacy@healthii.de<DoubleSpacer />

            <b>Geschäftsführer</b><Spacer />

            Ulrich Spindler<DoubleSpacer />

            <b>Handelsregister</b><Spacer />

            Kamer van Koophandel 84408553<DoubleSpacer />

            <b>Niederländische Umsatzsteuer-Identifikationsnummer</b><Spacer />
            NL863200175B01<DoubleSpacer />

            <b>Verantwortliche Apothekerin</b><Spacer />

            Tracy Overeem<Spacer />

            gesetzliche Berufsbezeichnung: Apotheker/in. Berufsbezeichnung verliehen in den Niederlanden, eingetragen im niederländischen Apothekenregister, BIG-Nummer: 69063089717<DoubleSpacer />

            <b>Zuständige Aufsichtsbehörde</b><Spacer />

            Staatstoezicht op de Volksgezondheid<Spacer />

            Inspectie Gezondheidszorg en Jeugd (IGJ)<br />
            Stadsplateau 1<br />
            3521 AZ Utrecht (Niederlande)<Spacer />

            Postanschrift: Postbus 2518, 6401 DA Heerlen<DoubleSpacer />

            <b>Streitschlichtung und Verbraucherstreitbeilegung</b><Spacer />

            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter <a href="https://ec.europa.eu/consumers/odr/" target="blank">https://ec.europa.eu/consumers/odr/</a> finden. Verbraucher haben die Möglichkeit, diese Plattform für die Beilegung ihrer Streitigkeiten zu nutzen.<Spacer />

            Wir sind nicht bereit und nicht verpflichtet an einem Streitbeilegungsverfahren vor einer Verbraucherstreitschlichtungsstelle teilzunehmen.<Spacer />

            Falls es jedoch einmal zu Problemen kommt, können Sie sich gerne an pharmacy@healthii.de wenden, um eine interne Einigung zu erzielen. Healthii setzt alles daran, Ihnen einen hervorragenden Kundenservice zu bieten und gemeinsam eine faire Lösung zu finden.
        </div>
    </>
}