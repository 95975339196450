export const Privacy = () => {
    return <>
        <div className="text-sm text-gray-200">
            <h1>Datenschutzbestimmungen</h1>

            <h2>General data</h2>

            Welcome to our privacy page. When using our store management, you trust us with your data. This Privacy Policy is intended to get you up to speed on which information we collect, why we collect it, and how we manage it. When you provide us with data, we can enhance our services to you. For instance, we can show you more meaningful queries and offers, help you contact individuals, or make offering services to others faster and easier. When you use our services, you need to be aware of how we use data and how to ensure your privacy. It is important; we trust that you will take the effort to read it thoroughly. Remember that you can track down controls to handle your data and ensure your protection and security. We have tried to keep it as simple as possible.

            <h2>Right to access, address and delete data and to object to data being processed</h2>
            Our clients reserve the ability to access, address and delete individual information that identifies them and to protest the handling of such information by making a compound requests whenever they choose. The company will make every effort to take reasonable precautions to ensure the security and privacy of personal information and to prevent such information from being altered, tampered with, destroyed or accessed by unauthorized persons. In any case, the company cannot check every single danger associated with the use of the Internet and, therefore, warns the customers of the Site of the possible dangers related to the operation and use of the Internet. The Site may contain links to other sites or other web sources. Because the company cannot control these sites and external sources, the business cannot be held responsible for the design or appearance of these sites and external sources and assumes no responsibility for the content, advertising, articles, administration or other materials available on or from such sites or external sources.

            <h2>The board of individual information</h2>
            You can view or alter your own information for a large number of our administrations online. You can also make choices about our product line and how your information is used. How you can have access to or check your own data depends on which services you use. You can choose to receive special communications from our store via email, text message, postal mail, or telephone. If you choose to receive special email or SMS messages from us and want to unsubscribe, you may do so by following the headings in the message. You may also make choices about receiving time-limited emails, calls, and mail by visiting and reviewing the Company’s Promotional Communications Manager, which allows you to make updates to your contact information, to monitor contact inclinations, to unsubscribe from email memberships, and to decide whether to share your contact information with our partners. These choices do not significantly impact the mandatory help correspondence that is important for certain business administrations.

            <h2>Data we gather</h2>
            Our business collects information to operate effectively and deliver the best experience for you with our services. Some of this information you give away easily, for example, when you create an individual record. We obtain some of this information by recording how you interact with our services, for example, by using progress such as tidbits and obtaining error reports or usage information from programs running on your device. We likewise get information from outsiders (counting other organizations). For example, we supplement the gathering we do by purchasing item segment information from different organizations. We as well utilize the services of different organizations to help us determine an area in dependence on your IP address so that we can tailor certain services to your area. The information we collect depends on the applications and elements you use.

            <h2>How we utilize your data</h2>
            Our Shop uses the information we collect for three basic purposes: to operate our business and provide the services we offer (including improvement and customization), to send correspondence, including specific interactions, and to advertise. For these reasons, we consolidate the information we collect about the different store administrations you use to give you more consistent, reliable, and personalized insight. However, to enhance protection, we have implemented mechanical and procedural safeguards to prevent certain information mixes. For example, we store information we receive from you when you are not authenticated (not logged in) separately from data that clearly identifies you, such as your name, email address, or phone number.

            <h2>Sharing your data</h2>
            We will share your own information with your consent or when it is important to complete an exchange or offer support that you have mentioned or authorized. For example, we share your information with outsiders when you advise us to do so. At the point when you give installment information to make a purchase, we will share installment information with banks and other elements that make installment exchanges or offer other types of monetary support, as well as to avoid trickery and lessen credit risk. We similarly share individual information between our controlled members and auxiliaries. We similarly share individual information with merchants or experts who act in our interest for the reasons outlined in this statement. For example, organizations that we have utilized to support customer service or to help us secure and procure our systems and administrations may require access to individual information in order to provide these services. In such cases, these same organizations must comply with our privacy and security obligations and may not use individual information they receive from us for any other purpose. We may also disclose individually identifiable information as part of a corporate exchange such as a consolidation or resource offering.
        </div>
    </>
}