
export const Home = () => {

    return <>
        <div className="text-center">
            <p className="text-sm text-gray-200" style={{
                marginTop: "1rem"
            }}>
                <b>Kontaktinformationen</b><br />
                Adresse: Pannenberg 16a, 5951 DM Belfeld (NL)<br />
                E-Mail: pharmacy@healthii.de<br />
                Telefon: +49 621 37031972<br />
                Servicezeiten: Montag - Freitag 8 Uhr - 17 Uhr<br />
            </p>
            <br />
            <p className="text-sm text-gray-200">
                Der Gesundheitsmarkt erlebt derzeit endlich die digitale
                Transformation. Wir wollen aus den neu entstehenden Möglichkeiten
                innovative Produkte schaffen, die es Menschen ermöglichen ihre
                Gesundheit besser zu managen.
                <br />
                <br />
                In einem ersten Schritt werden wir eine kundenzentrierte, moderne{" "}
                <span className="bg-clip-text bg-gradient-to-tr from-green-300 to-purple-400 text-transparent font-semibold">
                    Online-Apotheke
                </span>{" "}
                aufbauen, die durch Qualität und innovative Health-Services ein
                Wow-Erlebnis bei unseren Kunden auslösen soll.
            </p>
        </div>
    </>
}