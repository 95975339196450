import { Link } from 'react-router-dom';
import AppRoutes from "../constants/AppRoutes";

export const Footer = () => {
    return <>
        <footer className="text-center">
            <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8">
                <p className="text-sm text-white/50">
                    Zu Risiken und Nebenwirkungen lesen Sie die Packungsbeilage und fragen Sie Ihre Ärztin, Ihren Arzt oder in Ihrer Apotheke.
                    Sofern bei der Anwendung eines Arzneimittels Probleme auftreten, nehmen Sie bitte Kontakt mit Ihrer behandelnden Ärztin oder Ihrem behandelnden Arzt auf.
                </p>
                <div style={{ marginTop: 10 }} />
                <p className="text-sm text-white/50">
                    <Link to={AppRoutes.ROOT}>© 2024 Healthii Pharmacy B.V.</Link>{" | "}
                    <Link to={AppRoutes.IMPRINT}>Impressum</Link>{" | "}
                    <Link to={AppRoutes.PRIVACY}>Datenschutz</Link>
                </p>
            </div>
        </footer>
    </>
}